import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const Donate3 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div
          style={{
            backgroundColor: '#f2f2f2',
            borderRadius: '15px',
            padding: '20px'
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              border: '2px solid #333',
              borderRadius: '20px',
              margin: '20px',
              padding: '10px',
              textAlign: 'center'
            }}
          >
            <span
              style={{
                color: '#000',
                fontSize: '18px',
                marginLeft: '10px',
                fontFamily: 'UTM-APTIMABOLD',
                fontWeight: 'bold'
              }}
            >
              MUA GÓI 3
              <br/>
              BÁO CÁO ĐẦY ĐỦ 12 THÔNG TIN
          </span>
          </div>

          <div
            style={{
              // backgroundColor: '#000'
            }}
          >
            {/*  <div*/}
            {/*    style={{*/}
            {/*      width: '25%',*/}
            {/*      // backgroundColor: 'red',*/}
            {/*      display: 'inline-block'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*<span*/}
            {/*  style={{*/}
            {/*    color: '#333',*/}
            {/*    fontSize: '26px',*/}
            {/*    lineHeight: 1.2,*/}
            {/*    fontFamily: 'UTM-APTIMA',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  SƠ LƯỢC*/}
            {/*</span>*/}
            {/*  </div>*/}
            <div
              style={{
                width: '100%',
                // backgroundColor: 'green',
                display: 'inline-block'
              }}
            >

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                <p
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMA',
                    textAlign: 'center'
                  }}
                >
                  Để nhận bản báo cáo đầy đủ và các khóa học online, mời bạn vui lòng chuyển khoản 1.600.000đ đến tài khoản. Bạn vui lòng nhập chính xác các thông tin dưới đây để hệ thống nhận diện và mở khóa báo cáo.
                </p>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Ngân hàng: ' : 'Bank: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                Ngân hàng Seabank
                              </span>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Số tài khoản: ' : 'Bank number: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                0888111266
                              </span>
                <button className='btn btn-success btn-sm ms-4'
                        style={{ marginTop: '-10px' }}
                        onClick={() => navigator.clipboard.writeText('0888111266')}
                >
                  Copy
                </button>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                <span
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMA'
                  }}
                >
                  {LANGUAGE === 'VI' ? 'Tên tài khoản: ' : 'Owner: '}
                </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                  Nguyễn Tiến Thành
                </span>
              </div>


              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {'Số tiền: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                1.600.000 đ
                              </span>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Nội dung: ' : 'Content: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                  {'UHA ' + userInfo.phone}
                                </span>
                <button className='btn btn-success btn-sm ms-4'
                        style={{ marginTop: '-10px' }}
                        onClick={() => navigator.clipboard.writeText('UHA ' + userInfo.phone)}
                >
                  Copy
                </button>
              </div>

              <div
                style={{
                  margin: '5px',
                  padding: '10px 10px 10px 0px',
                  textAlign: 'center'
                }}
              >
                <span
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontFamily: 'UTM-APTIMA'
                  }}
                >
                  {LANGUAGE === 'VI' ? 'Mã QR: ' : 'QR code: '}
                </span>
                <br/>
                <div className='text-center'>
                  <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-thanh.jpg')} alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <p
            style={{
              color: '#000',
              fontSize: '20px',
              fontFamily: 'UTM-APTIMA',
              textAlign: 'center'
            }}
          >
            Sau khi hoàn thành chuyển khoản, bạn vui lòng đợi 1 phút để hệ thống xác nhận và bấm vào nút “Xác nhận đã chuyển khoản bên dưới”
          </p>

          {errorMessage &&
          <p
            style={{
              color: '#e22929',
              fontSize: '22px',
              fontFamily: 'UTM-APTIMA',
              textAlign: 'justify'
            }}
          >
            {errorMessage}
          </p>
          }

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              setErrorMessage('')
              axios.post(`${BACKEND_URL}/confirm-donate`, {
                phone: userInfo.phone,
              }).then(res => res.data)
                .then(resData => {
                  let userLevel = resData.data.user.level ?? 0
                  if (userLevel == 3) {
                    history.push('/result')
                    //window.open('https://user.robotanan.com/auth/login?phone=' + userInfo.phone, '_blank').focus()
                  } else {
                    setErrorMessage('Hiện tại hệ thống chưa nhận được số tiền chuyển khoản của bạn. Bạn vui lòng chuyển khoản nếu chưa kịp thực hiện. Nếu bạn đã hoàn thành chuyển khoản mà sau 5 phút vẫn chưa xuất hiện bản mô tả tính cách thì xin vui lòng vuốt dọc màn hình để làm mới nội dung, hoặc liên hệ hotline 0967886248 để được hỗ trợ kỹ thuật.')
                    window.scrollTo(0, document.body.scrollHeight)
                  }
                })
                .catch(err => {
                  setErrorMessage('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                })
            }}
          >
            <span
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#fff',
                letterSpacing: 1.1
              }}
            >
              XÁC NHẬN ĐÃ CHUYỂN KHOẢN
            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {Donate3}
