import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const CreateSale = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [phone, setPhone] = useState('');
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [saleAlias, setSaleAlias] = useState('');
  const [salePartnerAlias, setSalePartnerAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');
  const [hasModal, setHasModal] = useState(false);
  const [hasModalWarning, setHasModalWarning] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (name && phone && saleAlias && salePartnerAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, saleAlias, salePartnerAlias])

  const confirmRegisterSale = () => {
    setErrorMsg('')
    setIsCorrect(false)
    axios.post(`${BACKEND_URL}/self-create-sale`, {
      name: name,
      phone: phone,
      sale_alias: saleAlias,
      sale_partner_alias: salePartnerAlias,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setErrorMsg('Đăng ký thành công, chuyển về trang chủ trong 3s!')
          setTimeout(() => {
            history.push('/')
          }, 3000)
        } else {
          setErrorMsg(resData.message ?? 'Đăng ký không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
        }
      })
      .catch(err => {
        setErrorMsg('Lỗi hệ thống, vui lòng thử lại hoặc liên hệ với quản trị viên!')
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div>
          <img src={toAbsoluteUrl('/media/logos/company.jpg')} style={{ width: WIDTH }} alt='intro' />
        </div>

        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <p style={{
            color: '#008ffe',
            fontSize: '24px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '20px'
          }}>
            TẠO MÃ CỘNG TÁC VIÊN
          </p>

          <h3
            style={{ color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}
          >
            1. Tên cộng tác viên
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            2. Số điện thoại
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />
          <p className='d-none' style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA', textAlign: 'justify' }}>* Nếu xảy ra trường hợp một gia đình chỉ có 1 SĐT nhưng lại có nhiều thành viên tham gia phân tích, bạn có thể thêm vào phía sau 1 hoặc 2 số nữa để tránh trùng lặp</p>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            3. Mã cộng tác viên giới thiệu
          </h3>
          <input
            value={salePartnerAlias}
            onChange={(e) => setSalePartnerAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            4. Mã cộng tác viên tạo mới (chỉ gồm chữ hoa và số)
          </h3>
          <input
            value={saleAlias}
            onChange={(e) => setSaleAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#0dc735' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              confirmRegisterSale()
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              ĐĂNG KÝ
            </span>
          </button>


        </div>


      </div>

    </div>
  )
}

export {CreateSale}
