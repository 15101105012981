/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {SET_STEP_ZERO} from "../../setup/redux/userReducer";

const StepZero = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [phone, setPhone] = useState('');
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [agencyAlias, setAgencyAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [isValidUser, setIsValidUser] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');
  const [hasModal, setHasModal] = useState(true);
  const [hasModalWarning, setHasModalWarning] = useState(false);
  const [questionOne, setQuestionOne] = useState('');
  const [questionTwo, setQuestionTwo] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (phone && agencyAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [phone, agencyAlias])

  const checkUser = () => {
    setErrorMsg('')
    setIsCorrect(false)
    axios.post(`${BACKEND_URL}/check-user`, {
      phone: phone,
      agency_alias: agencyAlias,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          dispatch({
            type: SET_STEP_ZERO,
            payload: {
              phone: phone,
              agencyAlias: agencyAlias,
            }
          })
          history.push('/step-one')
          //setIsValidUser(true)
        } else {
          setErrorMsg(resData.message ?? 'Xác nhận không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
        }
      })
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div>
          <img src={toAbsoluteUrl('/media/logos/company.jpg')} style={{ width: WIDTH }} alt='intro' />
        </div>

        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <div>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Tìm hiểu thông tin về công nghệ Anbi </p>
            <button
              onClick={() => {
                history.push('/intro2')
              }}
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontFamily: 'UTM-APTIMABOLD',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              TÌM HIỂU ANBI
            </span>
            </button>
          </div>

          <div
            style={{
              paddingTop: '20px'
            }}
          >
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'UTM-APTIMA',
                color: '#aeafce',
              }}
            >>> Đăng nhập nếu bạn đã có tài khoản </p>
            <a href="https://user.robotanan.com/auth/login" target='_blank'>
              <button
                type="button"
                style={{
                  width: '100%',
                  padding: '15px',
                  backgroundColor: '#0dc735',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  fontFamily: 'UTM-APTIMA'
                }}
              >
              <span
                style={{
                  fontSize: '22px',
                  fontFamily: 'UTM-APTIMABOLD',
                  color: '#ffffff',
                  letterSpacing: 2.0
                }}
              >
                ĐĂNG NHẬP ANBI
              </span>
              </button>
            </a>
          </div>

        </div>

        <div
          style={{
            width: '100%',
            padding: '0px 30px 20px 30px'
          }}
        >
          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '350px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              overflow: 'scroll',
              padding: '20px'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/note-web.png')} style={{ width: '100%', height: 'auto' }} alt='' />

            <div
              onClick={() => setHasModal(false)}
              style={{
                width: '60%',
                border: '1px solid #ddd',
                borderRadius: '10px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                fontFamily: 'UTM-APTIMABOLD',
                margin: 'auto',
                marginTop: '20px',
                cursor: 'pointer'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TÔI ĐÃ HIỂU'
                :
                'I UNDERSTAND'
              }
            </div>

            <div style={{ height: '20px' }}></div>

          </div>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            Số điện thoại
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '18px', }}>
            Mã quà tặng
          </h3>
          <input
            value={agencyAlias}
            onChange={(e) => setAgencyAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '55px',
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '25px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#111',
              fontFamily: 'UTM-APTIMA',
              marginTop: '10px'
            }}
          />

          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#0dc735' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px',
              display: isValidUser ? 'none' : 'block',
            }}
            onClick={() => {
              checkUser()
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              XÁC NHẬN
            </span>
          </button>

          <div
            style={{
              display: !isValidUser ? 'none' : 'block'
            }}
          >
            {/*thong tin chuyen khoan*/}
            <div
              style={{
                // backgroundColor: '#000'
              }}
            >
              {/*  <div*/}
              {/*    style={{*/}
              {/*      width: '25%',*/}
              {/*      // backgroundColor: 'red',*/}
              {/*      display: 'inline-block'*/}
              {/*    }}*/}
              {/*  >*/}
              {/*<span*/}
              {/*  style={{*/}
              {/*    color: '#333',*/}
              {/*    fontSize: '26px',*/}
              {/*    lineHeight: 1.2,*/}
              {/*    fontFamily: 'UTM-APTIMA',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  SƠ LƯỢC*/}
              {/*</span>*/}
              {/*  </div>*/}
              <div
                style={{
                  width: '100%',
                  // backgroundColor: 'green',
                  display: 'inline-block'
                }}
              >

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                  <p
                    style={{
                      color: '#000',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMA',
                      textAlign: 'center'
                    }}
                  >
                    Để kích hoạt tài khoản tương ứng số điện thoại, mời bạn vui lòng chuyển khoản 50.000đ đến tài khoản. Bạn vui lòng nhập chính xác các thông tin dưới đây để hệ thống nhận diện và kích hoạt tài khoản.
                  </p>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Ngân hàng: ' : 'Bank: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMABOLD'
                    }}
                  >
                                Quân đội MBBank
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Số tài khoản: ' : 'Bank number: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMABOLD'
                    }}
                  >
                                1988288288
                              </span>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '-10px' }}
                          onClick={() => navigator.clipboard.writeText('1988288288')}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMA'
                  }}
                >
                  {LANGUAGE === 'VI' ? 'Tên tài khoản: ' : 'Owner: '}
                </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMABOLD'
                    }}
                  >
                  TRAN NGOC THIEN
                </span>
                </div>


                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {'Số tiền: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMABOLD'
                    }}
                  >
                                50.000 đ
                              </span>
                </div>

                <div
                  style={{
                    borderBottom: '1px dashed #b0b0b0',
                    margin: '5px',
                    padding: '10px 10px 10px 0px'
                  }}
                >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Nội dung: ' : 'Content: '}
                              </span>
                  <span
                    style={{
                      color: '#fd1a1c',
                      fontSize: '20px',
                      fontFamily: 'UTM-APTIMABOLD'
                    }}
                  >
                                  {'Anbi ' + userInfo.phone}
                                </span>
                  <button className='btn btn-success btn-sm ms-4'
                          style={{ marginTop: '-10px' }}
                          onClick={() => navigator.clipboard.writeText('Anbi ' + userInfo.phone)}
                  >
                    Copy
                  </button>
                </div>

                <div
                  style={{
                    margin: '5px',
                    padding: '10px 10px 10px 0px',
                    textAlign: 'center'
                  }}
                >
                <span
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontFamily: 'UTM-APTIMA'
                  }}
                >
                  {LANGUAGE === 'VI' ? 'Mã QR: ' : 'QR code: '}
                </span>
                  <br/>
                  <div className='text-center'>
                    <img style={{ width: '100%', borderRadius: '5px', marginTop: '10px' }} src={`https://img.vietqr.io/image/mbbank-1988288288-compact2.jpg?amount=50000&addInfo=Anbi%20${phone}&accountName=TRAN%20NGOC%20THIEN`} alt=""/>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#0dc735',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                borderWidth: '0px',
              }}
              onClick={() => {
                history.push('/step-one')
              }}
            >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              XÁC NHẬN
              <br/>
              ĐÃ CHUYỂN KHOẢN
            </span>
            </button>
          </div>


        </div>


      </div>

    </div>
  )
}

export {StepZero}
